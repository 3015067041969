jQuery(document).ready(function($) {

	$(document).foundation();

	// if ( $('.auto-reveal').length ) {
	// 	$('.auto-reveal').foundation('open');
	// }


});
